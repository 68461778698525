import { gql } from "@apollo/client";

export const OrderFields = `
  id
  status
  client
  totalQty
  totalPrice
  total
  created_at
  updated_at
  status
  client
  items
  totalQty
  totalPrice
  totalTax
  totalTaxByType
  total
  totalDiscount
  invoice
  comments
  notes
  payment
  estimate
  statusDates
  productionReady
  shippingReady
  isReplacement
  lot
  expiration
  lots
`;

const StockFields = `
  date
  product
  quantity
  id
  creator
  orderId
  lotId
  expDate
  unusedQty
  history
`;

export const GET_ORDERS = gql`
  query GetOrdersByStatus {
    orders(limit: 50, order_by: { created_at: desc }) {
      ${OrderFields}
    }
  }
`;

export const GET_ORDERS_BY_STATUS = gql`
  query GetOrders($status: String!) {
    orders(order_by: { created_at: desc }, where: {status: { _eq: $status}}) {
      ${OrderFields}
    }
  }
`;
export const GET_ORDERS_BY_CLIENT = gql`
  query GetOrders($id: String!) {
    orders(limit: 20, order_by: { created_at: desc }, where: {clientId: { _eq: $id}}) {
      ${OrderFields}
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query GetOrders($id: uuid!) {
    orders_by_pk(id: $id) {
      ${OrderFields}
    }
  }
`;

export const GET_ORDER = gql`
  query GetOrder($id: uuid!) {
    orders_by_pk(id: $id) {
      ${OrderFields}
    }
  }
`;

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($data: orders_insert_input!) {
    insert_orders_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrder($id: uuid!, $data: orders_set_input!) {
    update_orders_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const DELETE_ORDER_MUTATION = gql`
  mutation DeleteOrder($id: uuid!) {
    delete_orders_by_pk(id: $id) {
      id
    }
  }
`;

/**
 * STOCKS
 */

export const GET_STOCK = gql`
  query getStockByDate($from: date, $to: date) {
    stock(where: {date: {_gt: $from, _lt: $to}, creator: {_eq: "manual"}}, order_by: {created_at: desc}) {
      ${StockFields}
    }
  }
`;
export const GET_STOCK_V2 = gql`
  query getStockByDate($from: date, $to: date) {
    stock(where: {date: {_gt: $from, _lt: $to}, unusedQty: { _is_null: false }, creator: {_eq: "manual"}}, order_by: {created_at: desc}) {
      ${StockFields}
    }
  }
`;
export const GET_STOCK_V2_WHERE = gql`
  query getStockByDate($where: stock_bool_exp = {}) {
    stock(order_by: {created_at: desc}, where: $where) {
      ${StockFields}
  }
}
`;

export const GET_AVAILABLE_STOCK = gql`
  query getAvailableStock {
    stock(where: {unusedQty: {_gt: 0}}, order_by: {lotId: asc}) {
      ${StockFields}
    }
  }
`;
export const GET_STOCK_BY_IDS = gql`
  query getStockByIds($ids: [Int!]!) {
    stock(where: {id: {_in: $ids}}) {
      ${StockFields}
    }
  }
`;

export const GET_STOCK_AGGREGATE = gql`
  query getStockAggregate($product: String) {
    stock_aggregate(where: { product: { _eq: $product } }) {
      aggregate {
        sum {
          quantity
        }
      }
    }
  }
`;

export const CREATE_STOCK_MUTATION = gql`
  mutation CreateStock($data: stock_insert_input!) {
    insert_stock_one(object: $data) {
      id
    }
  }
`;

export const EDIT_STOCK_MUTATION = gql`
  mutation EditStock($id: Int!, $data: stock_set_input!) {
    update_stock_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const CREATE_STOCK_MULTIPLE_MUTATION = gql`
  mutation CreateStockMultiple($data: [stock_insert_input!]!) {
    insert_stock(objects: $data) {
      affected_rows
    }
  }
`;

export const REMOVE_STOCK_BY_ORDER = gql`
  mutation RemoveStockByOrderId($id: uuid!) {
    delete_stock(where: { orderId: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_STOCK_MULTIPLE_MUTATION = gql`
  mutation UpdateStockMultipleMutation($id: Int!, $data: stock_set_input!, $history: jsonb!) {
    update_stock(where: { id: { _eq: $id } }, _append: { history: $history }, _set: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_STOCK_HISTORY = gql`
  mutation UpdateStockHistoryMutation($id: Int!, $_delete_at_path: stock_delete_at_path_input!, $unusedQty: Int!) {
    update_stock(where: { id: { _eq: $id } }, _delete_at_path: $_delete_at_path, _set: { unusedQty: $unusedQty }) {
      affected_rows
    }
  }
`;
